<script setup lang="ts">
import MessageDialog from '@/components/MessageDialog.vue';

interface Props {
  title?: string
  content?: string
  type?: '' | 'danger'
  tooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  content: '',
  type: '',
  tooltip: true,
});

const classStr = computed(() => {
  const arr: string[] = [];
  if (props.title === '')
    arr.push('tooltip-info--title-less');
  if (props.type === 'danger')
    arr.push('tooltip-info--danger');
  return arr.join(' ');
});

const dialogSwitch = ref(false);

/** 點擊說明按鈕 */
function handleClickRemark() {
  dialogSwitch.value = true;
}
</script>

<template>
  <div
    class="tooltip-info z-1"
    :class="classStr"
  >
    <span>{{ title }}</span>
    <div :class="`tooltip-info__icon ${title.length ? 'relative top-1px' : ''}`">
      <el-tooltip v-if="props.tooltip" placement="top" :content="props.content">
        <i class="tooltip-icon icon-info_circle text-inherit" />
        <template #content>
          <slot />
        </template>
      </el-tooltip>
      <div v-else class="flex items-center">
        <el-button type="text" class="b-none p-0" @click="handleClickRemark">
          <i class="tooltip-icon icon-info_circle text-$gray-9!" />
          <span class="ml-3px text-$primary font-normal underline">說明</span>
        </el-button>
        <MessageDialog v-model:value="dialogSwitch" title="說明" :has-submit="false">
          <div class="px-10px w-full text-left text-14px text-$content font-normal">
            <slot />
          </div>
        </MessageDialog>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tooltip-info {
  display: inline-flex;
  align-items: center;

  &__icon {
    display: inline-flex;
    padding-left: 5px;

    .tooltip-info--title-less & {
      padding-left: 0;
    }
  }
}

.tooltip-info--danger .tooltip-icon {
  color: #fd3a54;
}
</style>
